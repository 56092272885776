<template >
  <div class="v-content">
    <div class="banner">
      <carousel></carousel>
    </div>
      
      <b-container fluid class=" bg-light">
        <b-row class="text-center">
          <b-col md >
            <h2>RECONOCIMIENTO FEIPOBOL 2021</h2>
            <b-img style="max-width: 20rem;" thumbnail fluid :src="require('@/assets/fotos/plaqueta.jpeg')" alt="Image 1"></b-img>
          </b-col>
          <b-col md>
            <h2> SIMPOSIO NACIONAL DEL LITIO </h2>
            <div>
              <b-embed
                type="iframe"
                aspect="16by9"
                :src="require('@/assets/videos/litio01.mp4')"
                allowfullscreen
              ></b-embed>
            </div>
          </b-col>
         
        </b-row> 
      </b-container>

  </div>
</template>

<script>
import Carousel from '../components/Carousel.vue'
export default {
  name: 'Home',
  components: { Carousel
    
  }
}
</script>

<style>

.titulo_principal {
    padding: 3rem 1rem;
    text-align: center;
    color: #2956b2;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.5rem;

    font-family: Montserrat,Helvetica,sans-serif;
    -webkit-font-smoothing: antialiased;
}

h2{
    border-radius: 8px;
    box-shadow: 0 12px 20px -10px rgb(255 90 25 / 28%), 0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(255 90 25 / 20%);
    background: #0d6efd;
    background: linear-gradient(0deg,#0d6efd,#5689cf); 
    width: 95%;
    text-align: center;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1rem 0.5rem; 
    font-size: 1rem;
    font-weight: 400;
    
    font-weight: 500;
    color: #e5eee9;
} 

.banner{
  text-align: center;
  width: 100%;
  margin-top: -0.2rem;




    padding: 0; 
    background-color: #fff;
    border: 1px solid #dee2e6; 
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;

}
</style>